.breadcrumb {
    margin-bottom: 8px;
    color: #969696;

    a {
        &:not(:last-child){
            &::after{
                content: '/';
                display: inline-block;
                margin: 0 8px;
            }
        }
        &.pointer-none{
            pointer-events: none;
        }
    }
}


