.footer__social {
    margin: 30px 0;

    a {
        margin-right: 25px;

        svg {
            font-size: 35px;
        }
    }
}
