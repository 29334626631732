@import "../../../styles/variables";
@import "../../../styles/mixins";

.intropartners {
    &__name {
        font-weight: 500;
        font-size: 24px;
    }

    &__name1 {
        max-width: 300px;
        font-size: 1.62em;
    }

    &__logo {
        width: 85px;
        height: auto;
        object-fit: contain;
        margin-right: 10px;
    }

    &__card {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:not(:first-child) {
            margin-bottom: 20px;
        }

        &:last-child {
            .intropartners__name {
                font-family: 'Madelyn', sans-serif;
                font-size: 55px;
            }
        }
    }

    &__social{
        margin: 10px 0 20px;

        a{
            margin-right: 30px;

            svg {
                font-size: 35px;
            }
        }
    } 

    @include mq("tablet") {
        .swiper-slide {
            height: calc((100% - 60px) / 2) !important;
        }
    }
}