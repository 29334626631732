@import "../../styles/variables";
@import "../../styles/mixins";

.project {
    h2 {
        font-size: 36px;
        color: #BF4597;
    }

    @include mq("tablet") {
        .swiper-slide {
            height: calc((100% - 60px) / 2) !important;
        }
    }
}