@import "../../styles/variables";
@import "../../styles/mixins";

.swiper-title {
    display: flex;
    justify-content: space-between;

    .swiper_buttons {
        display: flex;
        justify-content: end;
        margin-top: 5px;

        .swiper-button-disabled {
            svg {
                color: rgba(175, 108, 153, 0.429);
            }
        }

        svg {
            margin: 0 5px;
            color: #bf4597;
            cursor: pointer;
            font-size: 2rem;
        }
    }
}

.pSwiper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    .swiper-wrapper {
        flex-direction: unset;

        .swiper-slide {
            display: flex;
            justify-content: center;
            height: auto;
            max-height: 150px;

            a {
                display: flex;
            }

            img {
                object-fit: contain;
                object-position: center;
                width: 100%;
                height: auto;
            }
        }
    }
}
