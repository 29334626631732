@import "../../styles/variables";
@import "../../styles/mixins";

.appform {
    background: #fbf8ff;

    .MuiOutlinedInput-notchedOutline {
        border-color: #bf4597 !important;
    }

    .MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.Mui-required {
        color: #bf4597;
    }

    .MuiOutlinedInput-root {
        border-radius: 10px;
    }

    .error {
        color: #d92f46;
    }

    .container {
        padding: 0 80px;
    }

    h2 {
        text-align: center;
        font-size: 36px;
        font-weight: 400;
        color: #bf4597;
        margin-bottom: 32px;
    }

    @include mq("tablet") {
        .container {
            padding: 0px 40px;
        }
    }
}

.success__modal {
    padding: 0 30px 30px;

    h4 {
        text-align: center;
        font-size: 26px;
        font-weight: 300;
        margin-bottom: 32px;
    }

    h6 {
        font-weight: 300;
        font-size: 22px;
    }
}