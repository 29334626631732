@import "./variables";

.button {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    font-family: "Jost";
    user-select: none;
    position: relative;
    display: block;
    width: 177px;
    max-width: 100%;
    min-height: 52px;
    height: auto;
    z-index: 1;
    outline: none;
    border: 0;
    border-radius: 26px;
    background: transparent;
    transition: all 0.3s ease;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.08);

   &__purple{
        background-color: #BF4597;
   }

    &-print {
        color: #1c1c1c;
    }

    &_disabled {
        pointer-events: none;
        filter: grayscale(1);
    }

    &.button_shadow {
        &:hover {
            box-shadow: 0 0 0 0 transparent !important;
        }
    }

    &:focus-visible {
        box-shadow: 0 0 0 2px #000 !important;
    }

    &:active {
        border: 0;
        transform: scale(0.85);
        transition: all 0.05s;
    }
}
