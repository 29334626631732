@import "../../../styles/variables";
@import "../../../styles/mixins";

.intro {
    .mySwiper{
        height: 500px;
    }
    .swiper-button-prev,
    .swiper-button-next {
        color: #bf4597;
    }

    .swiper-button-disabled {
        color: rgba(175, 108, 153, 0.553);
    }

    .swiper-pagination-bullet {
        background: #bf4597;
    }

    img {
        height: 100%;
        max-height: 500px;
        object-fit: cover;
    }

    @include mq('tablet') {
        .mySwiper{
            height: 300px;
        }
    }
}
