@import "../../styles/variables";
@import "../../styles/mixins";

.statistics {
    h2 {
        color: #bf4597;
    }
    &__card {
        min-height: 300px;
        height: 100%;
        width: 100%;
        text-align: center;

        img {
            display: inline-block;
            width: 100%;
            height: 220px;
            object-fit: contain;
            padding: 0 60px;
        }

        h2 {
            color: #bf4597;
            font-size: 70px;
        }
    }
}
