@import "../../styles/variables";
@import "../../styles/mixins";

.stories {
    h2 {
        color: #bf4597;
        margin-bottom: 20px;
    }

    .row {
        --bs-gutter-x: 2rem;
    }

    .stories__card {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 0 29px -9px rgb(0 0 0 / 25%);

        a:first-child  {
            display: inline-block;
            width: 100%;
            height: 350px;
            img {
                object-fit: cover;
                width: 100%;
                max-width: 560px;
                height: 340px;
            }
        }

        p {
            margin-bottom: 0;
        }

        &-read {
            color: #bf4597;
            margin-top: auto;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
