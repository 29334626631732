@import "../../styles/variables";
@import "../../styles/mixins";

.help{
    &__title{
        padding: 0 40px;
        margin: 30px 0;
        color: #000!important;
    }

    &__content {
        * {
            background: transparent!important;
        }

        ul,
        ol {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none!important;
                padding: 0!important;
                margin: 0 0 10px 0!important;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 33px;
                    height: 33px;
                    margin-right: 10px;
                    margin-bottom: -10px;
                    background-image: url("../../resources/img/help/favicon.ico");
                    background-size: contain;
                }
            }
        }

        a {
            font-weight: 600;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    @include mq('tablet') {
        &__title {
            font-size: 24px;
            padding: 0;
        }
    }
}