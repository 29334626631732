@import "../../styles/variables";
@import "../../styles/mixins";

.story-name{
    h2{
        color: #BF4597;
        margin-bottom: 40px;
    }

    &__img{
        object-fit: contain;
        float: left;
        height: 100%;
        max-width: 100%;
        margin-right: 25px;
    }
}