@import "../../../styles/variables";
@import "../../../styles/mixins";

.intromap {
    position: relative;

    iframe{
        width: 100%;
        border-radius: 15px;
    }

    &__call{
        margin-top: 30px;
        color: #727070;
    }

    &__lenta{
        width: 100%;
        margin-top: -20px;
    }

    &__map {
        position: relative;
        margin: 16px 0;

        &-block {
            pointer-events: auto;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;

            &.show {
                pointer-events: none;
            }
        }
    }

    a:hover {
        text-decoration: underline;
    }
}