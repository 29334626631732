/**
  Переменные для цветов
 */

$cOne:          #003566;
$cTwo:          #003566;
$cThree:        #003566;
$cFour:         #003566;
$cFive:         #003566;

$cLink:         #003566;
$mShadow:       #00000050;
$menuHeight:    80px;
$transition:    cubic-bezier(0.51, 0.26, 0.21, 1.36);

$blue:          #0d6efd;
$indigo:        #6610f2;
$purple:        #6f42c1;
$pink:          #d63384;
$danger:        #dc3545;
$orange:        #fd7e14;
$warning:       #ffc107;
$green:         #198754;
$teal:          #20c997;
$cyan:          #9ed9ff;
$muted:         #9DA4AE;
$dark:          #343a40;

/**
  Переменные для времени
 */