@import "../../../styles/variables";
@import "../../../styles/mixins";

.introabout {
    color: #A0A0A0;

    &__content {
        color: #A0A0A0;

        p {
            max-width: 652px;
        }
    }

    &__img {
        max-width: 100%;
        max-height: 400px;
        height: 100%;
        width: 400px;
    }

    &__lenta1 {
        width: 100%;
        position: relative;
        height: 500px;
        margin-top: -400px;
        z-index: -1;
    }

    &__lenta {
        width: 100%;
        margin-top: -50px;
    }

    @include mq("tablet") {
        &__lenta1 {
            height: 200px;
            object-fit: contain;
            margin-top: -70px;
        }

        &__lenta {
            margin-top: 0;
        }
    }
}
