@import "../../styles/mixins";
@import "../../styles/variables";

.header {
    background-color: #fff;
    position: fixed;
    transition: top 0.2s;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: $menuHeight;
    z-index: 9;
    box-shadow: 0 0 30px #33333314;
}

// Desktop menu
.menu {
    text-transform: capitalize;
    font-size: 1em;
    user-select: none;
    display: flex;
    align-items: center;

    li {
        list-style-type: none;
    }

    &__brand {
        display: flex;
        align-items: center;

        img {
            max-width: 100%;
            height: 60px;
            margin-right: 10px;
        }

        span {
            text-align: right;
            font-family: "Madelyn", sans-serif;
            font-size: 44px;
            line-height: 1;

            @include mq('tablet') {
                font-size: 32px;
            }
        }
    }

    &__list {
        display: flex;
        padding: 0;
        margin: auto;
        text-transform: initial;
    }

    &__item {
        font-family: "Jost", sans-serif;
        color: #000000;
        border-radius: 20px;

        &:hover {
            color: #fff;
            background: #bf4597;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }

        .active {
            color: #fff;
            background: #bf4597;
        }

        & > a,
        & > span {
            position: relative;
            display: block;
            padding: 8px 12px;
            border-radius: 20px;
        }

        & > span {
            padding: 0;
        }
    }

    .nav-link {
        transition: none;
        color: #000000;
        padding: 0;
    }

    .dropdown {
        &.item {
            .nav-link {
                color: #000000;
                padding: 8px 12px;
                border-radius: 20px;

                &:hover {
                    color: #fff;
                    background: #bf4597;
                }
            }
        }

        &:hover .nav-link{
            color: #fff;
        }

        .dropdown-toggle.nav-link {
            padding: 8px 12px;
        }
    }

    .dropdown-menu {
        visibility: hidden;
        overflow: hidden;
        opacity: 0;
        display: block !important;
        min-width: auto;
        top: 100%;
        left: 50%;
        inset: 100% auto auto 50% !important;
        padding: 12px;
        border-radius: 5px;
        border: none;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16), 0 4px 18px rgba(0, 0, 0, 0.06);
        transform: translate(30px, 10px) scale(1) !important;
        transition: all 0.2s $transition;

        .dropdown-item {
            color: #000000;
            border-radius: 20px;
            padding: 8px 15px;
            background: transparent;

            &:hover {
                color: #fff !important;
                background: #bf4597;
            }
        }

        &.show {
            visibility: visible;
            opacity: 1;
            transform: translate(-50%, 10px) scale(1) !important;
        }
    }

    .dropdown-menu .show:hover a {
        color: #fff;
    }

    .context__dropdown {
        margin-right: 10px;
        border-radius: 20px;

        .dropdown-toggle::after {
            display: none;
        }

        .Mui-checked {
            color: #bf4597;
        }

        .MuiSwitch-track {
            background-color: rgba(175, 108, 153, 0.553) !important;
        }

        a {
            color: #000000;
            &:hover {
                color: #fff;
            }
        }

        &:hover {
            background: #bf4597;
        }

        h5 {
            text-align: center;
            font-size: 0.8em;
            white-space: nowrap;
        }

        .font-color {
            display: flex;
            margin-bottom: 10px;

            span {
                cursor: pointer;
                max-width: 50px;
                max-height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 3px 10px;
                margin: 0 3px;
                border: 1px solid #333;
            }

            span:nth-child(1) {
                background-color: #fff;
                color: #000 !important;
            }

            span:nth-child(2) {
                background-color: #000;
                color: #fff !important;
            }

            span:nth-child(3) {
                background-color: #939393;
                color: #fff !important;
            }

            span:nth-child(4) {
                background-color: #000;
                color: #a9e44d !important;
            }
        }

        .font-size {
            margin-bottom: 10px;
            display: flex;
            justify-content: center;

            span {
                color: #000 !important;
                border: 0.5px solid black;
                cursor: pointer;
                max-width: 50px;
                max-height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 3px 10px;
                margin: 0 3px;
                background-color: #fff;
            }
        }

        .MuiFormGroup-root {
            .MuiFormControlLabel-root {
                display: flex;
                justify-content: center;
                margin: 0;
            }
        }

        .reset-button {
            color: #000 !important;
            background-color: rgba(206, 136, 182, 0.553);
            display: flex;
            margin: 0 auto;
            border: none;
            border-radius: 5px;
        }
    }

    &__lang {
        color: #000000;
        display: flex;
        align-items: center;

        svg {
            margin-right: 7px;
        }

        .nav-item {
            padding: 8px 12px;
            border-radius: 20px;
            transition: all 0.2s ease;

            &:hover {
                background: #bf4597;
            }

            &.active {
                background: #bf4597;
            }

            &.dropdown {
                padding: 0;
            }

            .nav-link {
                color: #000000;
                position: relative;
                display: block;

                &::after {
                    margin-left: 13px;
                }

                &:hover {
                    color: #fff !important;
                }
            }
        }
    }

    &__hamburger {
        position: relative;
        cursor: pointer;
        width: 40px;
        height: 30px;
        z-index: 1;
        padding-left: 10px;
        margin-right: auto;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: #000;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
        }

        span:nth-child(1) {
            top: 0;
        }

        span:nth-child(2),
        span:nth-child(3) {
            top: 15px;
        }

        span:nth-child(4) {
            top: 29px;
        }

        &.active span {
            background: #fff;
        }

        &.active span:nth-child(1) {
            top: 15px;
            width: 0;
            left: 50%;
        }

        &.active span:nth-child(2) {
            transform: rotate(45deg);
        }

        &.active span:nth-child(3) {
            transform: rotate(-45deg);
        }

        &.active span:nth-child(4) {
            top: 15px;
            width: 0;
            left: 50%;
        }
    }

    @include mq("tablet-mid") {
        &__info {
            display: none;
        }

        .context__dropdown {
            display: none;
        }

        &__list {
            display: none;
        }

        &__lang {
            display: none;
        }

        &__brand {
            margin: 0 0 0 auto;
        }
    }

    @include mq("tablet-mid", min) {
        &__hamburger {
            display: none;
        }
    }

    @include mq('phablet') {
        &__brand {
            display: flex;
            flex-direction: row-reverse;
        }
    }
}

// Mobile menu
.mob-menu {
    background: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    opacity: 0;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    transition: all 0.3s ease;

    .overlay {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
    }

    &__content {
        background: #fff;
        box-shadow: 0 0 40px #00000040;
        position: relative;
        width: 300px;
        max-width: calc(100% - 65px);
        height: 100%;
        z-index: 1;
        transform: translateX(100%);
        transition: all 0.3s ease;
    }

    &__header {
        font-family: "Caveat";
        color: #fff;
        background-color: #bf4597;
        background-image: none;
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: inset 10px 0 30px #00000069;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: $menuHeight;
    }

    &__body {
        overflow: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100% - 80px);
        padding-bottom: 30px;

        .mob-context {
            margin: 20px auto 0;

            .Mui-checked {
                color: #bf4597;
            }

            .MuiSwitch-track {
                background-color: rgba(175, 108, 153, 0.553) !important;
            }

            a {
                color: #000000;
                &:hover {
                    color: #fff;
                }
            }

            h5 {
                text-align: center;
                font-size: 0.8em;
                white-space: nowrap;
            }

            .font-color {
                display: flex;
                margin-bottom: 10px;

                span {
                    border: 0.5px solid black;
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 3px;
                    border: 1px solid #fff;
                }

                span:nth-child(1) {
                    background-color: #fff;
                    color: #000 !important;
                }

                span:nth-child(2) {
                    background-color: #000;
                    color: #fff !important;
                }

                span:nth-child(3) {
                    background-color: #939393;
                    color: #fff !important;
                }

                span:nth-child(4) {
                    background-color: #000;
                    color: #a9e44d !important;
                }
            }

            .font-size {
                margin-bottom: 10px;
                display: flex;
                justify-content: center;

                span {
                    color: #000 !important;
                    border: 0.5px solid black;
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 3px;
                    background-color: #fff;
                }
            }

            .MuiFormGroup-root {
                .MuiFormControlLabel-root {
                    display: flex;
                    justify-content: center;
                    margin: 0;
                }
            }

            .reset-button {
                color: #000 !important;
                background-color: rgba(206, 136, 182, 0.553);
                display: flex;
                margin: 0 auto;
                border: none;
                border-radius: 5px;
            }
        }
    }

    &__list {
        color: #000;
        padding: 0;
        margin: 0;
    }

    &__item {
        & > a {
            position: relative;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 1px;
            display: block;
            width: 100%;
            padding: 10px 15px;
            transition: all 0.2s ease;

            &::before {
                content: "[";
                right: 2px;
            }

            &::after {
                content: "]";
                top: 1px;
                left: 6px;
            }

            &::before,
            &::after {
                opacity: 0;
                position: relative;
                font-size: 18px;
                font-weight: 500;
                transition: all 0.2s ease;
            }

            &.active {
                box-shadow: inset 10px 10px 20px #00000020, inset -10px -10px 20px #fff, 0px 5px 15px #00000020;

                &::before,
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    &__info {
        text-align: center;
        padding: 0 15px;
        margin-top: 20px;

        .menu__number {
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: initial;
            margin: 10px 0 0;
        }
    }

    .menu__number {
        font-weight: 500;
        font-size: 16px;
        margin-right: 0;
    }

    &__social {
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 15px;

        a {
            line-height: .9;
            text-align: center;
        }

        & svg {
            margin: 0 0.5rem;
        }
    }

    &__lang {
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;

        li {
            color: #000000;
            cursor: pointer;
            font-weight: 500;
            padding: 0.5rem 1rem;
            margin-top: 10px;
            border-radius: 30px;
            transition: all 0.3s ease;

            &.active {
                color: #bf4597 !important;
                box-shadow: inset 10px 10px 20px #00000020, inset -10px -10px 20px #fff, 0px 5px 15px #00000020;
            }
        }
    }

    &.active {
        visibility: visible;
        opacity: 1;

        .mob-menu__content {
            transform: translateX(0);
            transition-delay: 0.2s;
        }
    }
}

@include mq("phablet") {
    .menu__lang .dropdown-menu {
        inset: unset !important;
        padding: 10px;

        &.show {
            transform: translate(-15%, 10px) scale(1) !important;
        }
    }
}
