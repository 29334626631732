@import "../../styles/variables";
@import "../../styles/mixins";

.footer {
    position: relative;
    z-index: 2;
    padding: 40px 0;
    margin-top: auto;
    color: #000;
    background: rgb(255 244 251);

    &__card {
        font-weight: 500;
        font-size: 24px;
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        &:first-child {
            font-family: 'Madelyn', sans-serif;
            font-size: 55px;
        }
    }

    &__logos {
        img {
            float: left;
            width: 80px;
            height: auto;
            object-fit: contain;
            margin-right: 10px;
        }
    }

    &__last {
        object-fit: contain;
        object-position: left;
        max-width: 100%;
        height: 40px;
        margin-left: 10px;
    }

    a:hover {
        text-decoration: underline;
    }

    @include mq("tablet") {
        .row {
            text-align: center;
        }
    }
}
