@import "../../styles/variables";
@import "../../styles/mixins";

.video {
    h2 {
        color: #bf4597;
        margin-bottom: 20px;
    }

    .row {
        --bs-gutter-x: 2rem;
    }

    .video__card {
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        box-shadow: 0 0 29px -9px rgb(0 0 0 / 25%);
        height: 100%;

        .video__overlay {
            width: 100%;
            height: 350px;
            cursor: pointer;
            position: relative;

            .video__overlay-img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            svg {
                background-color: rgb(255 255 255 / 76%);
                border-radius: 50%;
                font-size: 50px;
                transition: 0.4s;
            }

            img {
                object-fit: cover;
                width: 100%;
                max-width: 560px;
                height: 340px;
            }
        }

        .video__overlay:hover {
            svg {
                transform: scale(1.4);
            }
        }

        p {
            margin-bottom: 0;
        }
    }
}
