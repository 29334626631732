@import "./mixins";
@import "./variables";

// Texts

.text-error {
    color: $danger;
}

.text-warning {
    color: $warning;
}

// Section title
.section__title {
    color: #BF4597;
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: 700;

    @include mq('tablet') {
        text-align: center;
        font-size: 28px;
        margin-bottom: 25px;
    }
}

// Sizes

.mh-md-100vh {
    @include mq("tablet", min) {
        min-height: 100vh;
    }
}

.MuiInputLabel-root {
    font-size: 1em!important;
}

.dropdown-item,
.dropdown-menu {
    font-size: 1em;
}